<template>
  <div>
    <a-modal
      :visible="visible"
      title="配置选择"
      width="55%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-select
        style="width: 100%"
        v-model="selectedRowKeys"
        allowClear
        mode="multiple"
        showSearch
        :showArrow="false"
        v-if="tableDate.length"
        optionFilterProp="label"
        :open="false"
      >
        <a-select-option
          v-for="configurationItem in configurationItems"
          :label="configurationItem.identifier"
          :key="configurationItem.id"
          >{{ configurationItem.identifier }}</a-select-option
        >
      </a-select>
      <div class="property-operations">
        <div></div>
        <div class="data-filter">
          <a-input
            clearable
            placeholder="请输入标识符或备注内容"
            v-model="searchKeyword"
            class="search-keyword"
            @keyup.enter.native="listProductConfiguration"
          ></a-input>
          <a-button class="search-btn" type="primary" @click="listProductConfiguration()">搜索</a-button>
        </div>
      </div>
      <a-table
        size="middle"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        :columns="this.$constRepository.productConfiguration.columns"
        :data-source="tableDate"
        class="property-table"
        rowKey="id"
      >
        <template slot="description" slot-scope="scope">{{
          scope.defaultMessage ? scope.defaultMessage : '暂无'
        }}</template>
        <template slot="action" slot-scope="scope">
          <a-popover trigger="click" title="配置字段" placement="left">
            <template #content>
              <div class="popover-content">
                <a-descriptions bordered>
                  <a-descriptions-item label="配置ID">{{ scope.id }} </a-descriptions-item>
                  <a-descriptions-item label="配置标识符"> {{ scope.identifier }} </a-descriptions-item>
                  <a-descriptions-item label="配置描述" v-if="scope.description">{{
                    scope.description.defaultMessage ? scope.description.defaultMessage : '暂无'
                  }}</a-descriptions-item>
                  <a-descriptions-item label="配置项定义唯一标识"> {{ scope.encode }} </a-descriptions-item>
                  <a-descriptions-item label="属性类型">
                    <span>{{ propertyType(scope) }}</span>
                  </a-descriptions-item>
                  <a-descriptions-item label="编辑方式">
                    <span>{{ propertyEditMode(scope) }}</span>
                  </a-descriptions-item>
                  <a-descriptions-item label="默认值">
                    <span>{{ scope.type.defaultValue || '无' }}</span>
                  </a-descriptions-item>
                  <template v-if="scope.type.editMode == $constRepository.productFormItem.editMode.input">
                    <a-descriptions-item label="范围">
                      <span v-if="scope.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
                        `${scope.type.range.min}-${scope.type.range.max}`
                      }}</span>
                      <span v-else>无</span>
                    </a-descriptions-item>
                  </template>
                  <template v-if="scope.type.editMode == $constRepository.productFormItem.editMode.select">
                    <a-descriptions-item label="选项">
                      <p v-for="(option, index) in scope.type.options" :key="index">
                        {{ `${option.value}:${option.description.defaultMessage}` }}
                      </p>
                    </a-descriptions-item>
                  </template>
                </a-descriptions>
              </div>
            </template>
            <a-button type="link">查看</a-button>
          </a-popover>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'ProductConfigurationSelector',
  components: {},
  data() {
    return {
      visible: false,
      selectType: 'checkbox',
      resultCallBack: {
        onSubmit: Function,
      },
      searchKeyword: '',
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      selectedRowKeys: [],
      tableDate: [],
      configurationItems: [],
      metaData: {},
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    async listProductConfiguration() {
      const response = await this.$apiManager.productConfiguration.getProductConfigurationItems({
        unpaged: true,
        searchKey: this.searchKeyword,
      });
      this.tableDate = response.data.elements;
      this.configurationItems = !this.configurationItems.length ? response.data.elements : this.configurationItems;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async showCreateDialog(callback) {
      await Promise.all([this.getMetaData(), this.listProductConfiguration()]);
      // 将已选择置于表格前;
      const target = [];
      this.tableDate = this.tableDate.filter((item) => {
        if (!this.selectedRowKeys.includes(item.id)) {
          return true;
        }
        target.push(item);
        return false;
      });
      this.tableDate = [...target, ...this.tableDate];
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      const keys = this.selectedRowKeys.map((key) => ({ id: key }));
      const params = lodash.intersectionBy(this.configurationItems, keys, 'id');
      this.resultCallBack.onSubmit(this.selectedRowKeys, params);
      this.hideDialog();
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
  },
  computed: {
    propertyType() {
      return (property) => {
        const propertyName = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        return propertyName ? propertyName.name : '';
      };
    },
    propertyEditMode() {
      return (property) => {
        const modeName = this.$constRepository.productFormItem.editModeText[property.type.editMode];
        return modeName || '';
      };
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.popover-content {
  max-height: 400px;
  overflow-y: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.property-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .property-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
