<template>
  <div>
    <a-modal
      :visible="visible"
      title="属性选择"
      width="65%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-select
        style="width: 100%"
        v-model="selectedRowKeys"
        allowClear
        mode="multiple"
        showSearch
        :showArrow="false"
        v-if="tableData.length"
        optionFilterProp="label"
        :open="false"
      >
        <a-select-option v-for="property in tableData" :label="property.name" :key="property.id">
          {{ property.identifier }}
        </a-select-option>
      </a-select>
      <div class="property-operations">
        <div></div>
        <div class="data-filter">
          <a-select v-if="filterSelected" v-model="filterSelected" style="width: 200px">
            <a-icon slot="suffixIcon" type="smile" />
            <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">
              {{ filter.label }}
            </a-select-option>
          </a-select>
          <a-input v-model="searchKeyword" class="search-keyword" allow-clear @keyup.enter.native="doSearch"></a-input>
          <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
        </div>
      </div>
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        size="middle"
        :columns="this.$constRepository.property.columns"
        :data-source="tableData"
        class="property-table"
        rowKey="id"
        :pagination="{
          current: pageInfo.page,
        }"
        @change="pageChange"
      >
        <template slot="propertyType" slot-scope="scope">
          {{ scope === $constRepository.property.FILTER_OPTION_PROPERTY_NODE_FATHER.value ? '父节点' : '子节点' }}
        </template>
        <template slot="createdTime" slot-scope="scope">
          {{ scope ? moment(scope).format('YYYY年MM月DD日') : '未知' }}
        </template>
        <template slot="lastModificationTime" slot-scope="scope">
          {{ scope ? moment(scope).format('YYYY年MM月DD日') : '未知' }}
        </template>
        <template slot="action" slot-scope="scope">
          <a-popover trigger="click" title="属性字段" placement="right">
            <template #content>
              <loaded-device-property-card :properties="[scope]" />
            </template>
            <a-button type="link">查看</a-button>
          </a-popover>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';
import moment from 'moment';
import LoadedDevicePropertyCard from '../../loaded-device/components/loadedDevicePropertyCard';

export default {
  name: 'LoadedDevicePropertiesSelector',
  components: { LoadedDevicePropertyCard },
  data() {
    return {
      visible: false,
      selectType: 'checkbox',
      resultCallBack: {
        onSubmit: Function,
      },
      filterSelected: this.$constRepository.property.FILTER_OPTION_PROPERTY_NAME.value,
      filterOptions: this.$constRepository.property.FILTER_OPTIONS,
      searchKeyword: '',
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      selectedRowKeys: [],
      tableData: [],
      originTableData: [],
      properties: [],
      moment,
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    sortData(array) {
      // 按属性标识符，父属性优先，属性定义唯一标识符，三元组来排序
      // 根据id分组，子节点使用parentId
      const group = lodash.groupBy(array, (item) => {
        if (item.parentId) {
          return item.parentId;
        }
        return item.id;
      });
      const groupArray = Object.values(group);
      // 根据属性类型升序排序 1:父节点 2:子节点,使父节点在子节点前
      const sortArray = groupArray.map((properties) => lodash.sortBy(properties, 'propertyType'));
      // 扁平化数组
      const flatArray = lodash.flatMapDeep(sortArray);
      // 根据唯一标识符,属性定义唯一标识符排序
      return lodash.sortBy(flatArray, ['identifier', 'encode']);
    },
    async listProperties() {
      const params = {
        unpaged: true,
      };
      const response = await this.$apiManager.property.getProperties(params);
      this.tableData = this.sortData(response.data.elements);
      this.originTableData = lodash.cloneDeep(this.tableData);
      this.properties = !this.properties.length ? response.data.elements : this.properties;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async showCreateDialog(callback) {
      await this.listProperties();
      // 将已选择置于表格前;
      const target = [];
      this.tableData = this.tableData.filter((item) => {
        if (!this.selectedRowKeys.includes(item.id)) {
          return true;
        }
        target.push(item);
        return false;
      });
      this.tableData = [...target, ...this.tableData];
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    showUpdateDialog(pararms, callback) {
      this.productCategoryForm = pararms;
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      const keys = this.selectedRowKeys.map((key) => ({ id: key }));
      const params = lodash.intersectionBy(this.properties, keys, 'id');
      this.resultCallBack.onSubmit(this.selectedRowKeys, params);
      this.hideDialog();
    },
    pageChange(value) {
      this.pageInfo.page = value.current;
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.tableData = this.originTableData.filter((item) => {
        const searchKey = this.searchKeyword.toLocaleLowerCase();
        return (
          item.identifier.toLocaleLowerCase().includes(searchKey) || item.name.toLocaleLowerCase().includes(searchKey)
        );
      });
    },
  },
};
</script>
<style lang="less" scoped>
.popover-content {
  width: 50vw;
}
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.property-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .property-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
