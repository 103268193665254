<template>
  <a-modal v-if="visible" width="80%" @ok="handleOk" title="产品型号" :visible="visible" @cancel="closeModal">
    <a-transfer
      :data-source="tableData"
      :target-keys="targetKeys"
      show-search
      :filter-option="filterOption"
      :show-select-all="false"
      @change="onChange"
      :search-placeholder="'请输入产品名称'"
    >
      <template
        slot="children"
        slot-scope="{
          props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
          on: { itemSelectAll, itemSelect },
        }"
      >
        <a-table
          :loading="tableLoading"
          :row-selection="getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
          :columns="columns"
          :data-source="filteredItems"
          size="small"
        >
          <template slot="type" slot-scope="scope">
            {{ productTypes(scope) }}
          </template>
        </a-table>
      </template>
    </a-transfer>
  </a-modal>
</template>

<script>
import difference from 'lodash/difference';

export default {
  name: 'ProductModelTransferModal',
  data() {
    return {
      tableData: [],
      tableLoading: false,
      visible: false,
      targetKeys: [],
      columns: [
        {
          dataIndex: 'identifier',
          title: '产品型号',
        },
        {
          dataIndex: 'name',
          title: '产品名称',
        },
      ],
    };
  },
  methods: {
    openModal(selectedList) {
      this.listProducts();
      this.targetKeys = selectedList.map((item) => item.id);
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
      this.tableData = [];
      this.targetKeys = [];
    },
    handleOk() {
      const targetModels = this.tableData.filter((model) => this.targetKeys.includes(model.id));
      this.$emit('selectedModels', targetModels);
      this.closeModal();
    },
    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },
    listProducts() {
      this.tableLoading = true;
      const params = { unpaged: true };
      this.$apiManager.productModel
        .getProductModel(params)
        .then((response) => {
          this.tableData = response.data.elements.map(({ id, identifier, name }) => ({
            key: id,
            id,
            identifier,
            name,
          }));
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getRowSelection({
 disabled, selectedKeys, itemSelectAll, itemSelect,
}) {
      return {
        getCheckboxProps: (item) => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
    filterOption(inputValue, item) {
      return item.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    },
  },
};
</script>
